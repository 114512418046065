<template>
  <div
    id="navigation-bar"
    class="row no-gutters justify-content-between align-items-center"
  >
    <component
      :is="component"
      v-if="!$route.name.includes('embed') && (uiFlags.isUnitPreviewMode || !['inquiry', 'unit'].includes($route.name))"
      class="back-btn col-auto cursor-pointer text-secondary"
      :class="uiFlags.isUnitPreviewMode ? 'go-back-button position-absolute pl-2 pr-4' : ''"
      @click.prevent="back"
    >
      <i
        class="sdicon-back"
        :class="uiFlags.isUnitPreviewMode ? 'text-white mr-1 fs-17 fs-sm-14 lh-sm-14' : 'fs-24'"
      />
      <div
        v-if="uiFlags.isUnitPreviewMode"
        class="text-white fs-17 lh-24 fs-sm-14 lh-sm-20"
      >
        Back to your tour details
      </div>
    </component>

    <div
      v-if="!$route.name.includes('.help')"
      class="help-btn ml-auto col-auto mr-2 height-30"
    >
      <router-link
        :to="helpRoute"
        class="d-flex cursor-pointer"
      >
        <i class="sdicon-help" />
      </router-link>
      <transition name="fade">
        <router-link
          v-if="uiFlags.isHelpPopoverVisible"
          id="help-popover"
          :to="helpRoute"
        >
          <h2 class="mb-3">
            Have any questions?
          </h2>
          <p class="text-secondary">
            Click here to get help for scheduling a tour.
          </p>
        </router-link>
      </transition>
    </div>
  </div>
</template>

<script>
import {
  reactive, defineComponent, watch, ref, inject,
} from '@vue/composition-api';

import InquiryStatus from '../constants/InquiryStatus';
import * as Segment from '../services/segment';
import routeUtils from '../utils/routeUtils';
import RouteNames from '../constants/RouteNames';

const HELP_POPOVER_TIMEOUT = 6000;

export default defineComponent({
  name: 'NavigationBar',
  setup(props, context) {
    const { $router, $route, $store } = context.root;
    const isMobile = inject('isMobile');

    const uiFlags = reactive({
      isHelpPopoverVisible: false,
      isUnitPreviewMode: routeUtils.computeIsUnitPreviewMode($route.name),
    });
    const component = ref('div');

    watch(() => context.root.$route, watchForBackButtonOnRouteChange);
    const helpRoute = getHelpRoute();

    let timeoutId;
    if ($store.getters.isUnitLandingPage || $store.getters.getProperty('inquiry.status') === InquiryStatus.PENDING) {
      timeoutId = setTimeout(showHelpPopover, HELP_POPOVER_TIMEOUT);

      document.addEventListener('click', handleDocumentClick);
    }

    return {
      isMobile,
      back,
      helpRoute,
      uiFlags,
      component,
    };

    function watchForBackButtonOnRouteChange(nextRoute) {
      const isPrevRouteUnitPreview = uiFlags.isUnitPreviewMode;
      const isCurrentRouteUnitPreview = routeUtils.computeIsUnitPreviewMode(nextRoute.name);
      if (isPrevRouteUnitPreview && !isCurrentRouteUnitPreview) {
        component.value = 'div';
        uiFlags.isUnitPreviewMode = false;
      }
    }

    function back() {
      // In cases that we showing up the back button but lead came direcftly into the choose time page
      // -- take him back to unit home if pressed on back and avoid re-fetch the data
      const canGoBack = window.history.length > 2;
      if ($route.name === RouteNames.UNIT_CHOOSE_TOUR_TIME || !canGoBack) {
        $router.push({ name: RouteNames.UNIT_HOME, params: { uuid: $route.params.uuid } });
      } else {
        $router.go('-1');
      }
    }

    function getHelpRoute() {
      const routeNamePrefix = $store.getters.isUnitLandingPage ? 'unit' : 'inquiry';
      return {
        name: `${routeNamePrefix}.help`,
        params: {
          uuid: $route.params.uuid,
        },
      };
    }

    function handleDocumentClick() {
      if (uiFlags.isHelpPopoverVisible) {
        uiFlags.isHelpPopoverVisible = false;
        Segment.event(Segment.events.HelpPopoverClosed);
      } else {
        clearTimeout(timeoutId);
      }
      document.removeEventListener('click', handleDocumentClick);
    }

    function showHelpPopover() {
      uiFlags.isHelpPopoverVisible = true;
      Segment.event(Segment.events.HelpPopoverOpened);
    }
  },
});
</script>

<style lang="scss" scoped>
#navigation-bar {
  width: 100%;
  min-height: var(--navigation-bar-height);

  .go-back-button {
    height: 40px;
  }

  i.sdicon-help {
    font-size: 32px;
  }

  .sdicon-help {
    background: white;
    border-radius: 50%;
  }

  .help-btn {
    z-index: $navigation-bar-z-index;
  }

  .help-btn-with-text {
    border: 2px solid #fff;
    border-radius: 50px;

    .sdicon-help {
      margin-right: 5px;
      background: none;
    }
  }

  .height-30 {
    height: 30px;
  }

  #help-popover {
    position: absolute;
    top: 4px;
    right: 100%;
    display: block;
    width: 268px;
    margin-right: 1rem;
    padding: 16px;
    background: $white;
    border: 1px solid gray('dark');
    border-radius: $border-radius;
    box-shadow: $box-shadow-lg;
  }

  .sdicon-back {
    margin-left: 10px;
  }
}
</style>
