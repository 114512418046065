<template>
  <vue-easy-lightbox
    :move-disabled="!isMobile"
    :visible="state.isVisible"
    :imgs="state.images"
    :index="state.index"
    style="z-index: 9999999;"
    @hide="handleHide"
  />
</template>

<script>
import { reactive, defineComponent } from '@vue/composition-api';
import VueEasyLightbox from 'vue-easy-lightbox';
import { isMobileViewport } from '@/utils/deviceUtils';

export default defineComponent({
  name: 'LightBox',
  components: {
    VueEasyLightbox,
  },
  setup(props, context) {
    const isMobile = isMobileViewport();

    context.root.$on('showLightbox', (e) => {
      show({ index: e.index || 0, images: e.images });
    });

    const state = reactive({
      isVisible: false,
      index: 0,
      images: [],
    });

    function show({ index, images }) {
      state.isVisible = true;
      state.index = index;
      state.images = images;
    }

    function handleHide() {
      state.isVisible = false;
    }

    return {
      isMobile,
      handleHide,
      isVisible: state.isVisible,
      state,
    };
  },
});
</script>

<style>
.toolbar-btn__rotate,
.toolbar-btn__resize {
  display: none;
}
</style>
