export default class InquirySource {
  static MANUAL = 'Manual';

  static PM_WEBSITE = 'PM Website';

  static UNKNOWN = 'Unknown';

  static WEBSITE = 'Website';

  static EMBEDDED_SCHEDULING = 'Embedded Scheduling';
}
