import Vue from 'vue';
import VueRouter from 'vue-router';
import { get } from 'lodash-es';
import store from './store';
import InquiryStatus from './constants/InquiryStatus';
import RouteNames from './constants/RouteNames';

if (window.location.hash) {
  window.location.href = window.location.hash.substr(2);
}

Vue.use(VueRouter);

const routes = [
  {
    path: '/unit/:uuid',
    name: RouteNames.UNIT_HOME,
    component: () => import('./pages/Unit'),
  },
  {
    path: '/:uuid',
    name: RouteNames.INQUIRY_HOME,
    component: () => import('./pages/Inquiry'),
  },
  {
    path: '/:uuid',
    name: RouteNames.INQUIRY_PREVIEW,
    component: () => import('./pages/Inquiry'),
  },
  {
    path: '/unit/:uuid/self-showing/help',
    name: RouteNames.UNIT_SELF_SHOWING_HELP,
    component: () => import('./pages/SelfShowing/SelfShowingHelp'),
  },
  {
    path: '/:uuid/self-showing/help',
    name: RouteNames.INQUIRY_SELF_SHOWING_HELP,
    component: () => import('./pages/SelfShowing/SelfShowingHelp'),
  },
  {
    path: '/unit/:uuid/help',
    name: RouteNames.UNIT_HELP,
    component: () => import('./pages/Help'),
  },
  {
    path: '/:uuid/help',
    name: RouteNames.INQUIRY_HELP,
    component: () => import('./pages/Help'),
  },
  {
    path: '/unit/:uuid/map',
    name: RouteNames.UNIT_MAP_VIEW,
    component: () => import('./pages/Map'),
  },
  {
    path: '/:uuid/map',
    name: RouteNames.INQUIRY_MAP_VIEW,
    component: () => import('./pages/Map'),
  },
  {
    path: '/:uuid/choose-unit',
    name: RouteNames.INQUIRY_CHOOSE_UNIT,
    component: () => import('./pages/ChooseUnit'),
    meta: {
      allowedInquiryStatuses: [InquiryStatus.PENDING],
    },
  },
  {
    path: '/embed/:businessId/:unitPmsCode',
    name: RouteNames.EMBED_UNIT_SCHEDULING,
    component: () => import('./pages/EmbeddedUnit.vue'),
  },
  {
    path: '/unit/:uuid/choose-tour-time',
    name: RouteNames.UNIT_CHOOSE_TOUR_TIME,
    component: () => import('./pages/ChooseTourTime'),
  },
  {
    path: '/:uuid/choose-tour-time',
    name: RouteNames.INQUIRY_CHOOSE_TOUR_TIME,
    component: () => import('./pages/ChooseTourTime'),
    meta: {
      allowedInquiryStatuses: [InquiryStatus.PENDING],
    },
  },
  {
    path: '/unit/:uuid/contact-info-form',
    name: RouteNames.UNIT_CONTACT_INFO_FORM,
    component: () => import('./pages/ContactInfoForm'),
  },
  {
    path: '/:uuid/contact-info-form',
    name: RouteNames.INQUIRY_CONTACT_INFO_FORM,
    component: () => import('./pages/ContactInfoForm'),
    meta: {
      allowedInquiryStatuses: [InquiryStatus.PENDING],
    },
  },
  {
    path: '/:uuid/information',
    name: RouteNames.INQUIRY_INFORMATION,
    component: () => import('./pages/Information.vue'),
    meta: {
      allowedInquiryStatuses: [InquiryStatus.PENDING],
    },
  },
  {
    path: '/:uuid/qualification-criteria',
    component: () => import('./pages/QualificationCriteria'),
    children: [
      {
        path: '',
        name: RouteNames.INQUIRY_QUALIFICATION_CRITERIA,
        component: () => import('./pages/QualificationCriteria/NonQuestions'),
        meta: {
          allowedInquiryStatuses: [InquiryStatus.PENDING],
        },
      },
      {
        path: ':question',
        name: RouteNames.INQUIRY_QUALIFICATION_CRITERIA_QUESTION,
        component: () => import('./pages/QualificationCriteria/Question'),
        meta: {
          allowedInquiryStatuses: [InquiryStatus.PENDING],
        },
      },
    ],
  },
  {
    path: '/:uuid/unqualified',
    name: RouteNames.INQUIRY_UNQUALIFIED,
    component: () => import('./pages/Unqualified'),
  },
  {
    path: '/:uuid/identification',
    name: RouteNames.INQUIRY_IDENTIFICATION,
    component: () => import('./pages/Identification'),
    meta: {
      allowedInquiryStatuses: [InquiryStatus.PENDING],
    },
  },
  {
    path: '/:uuid/verification',
    name: RouteNames.INQUIRY_VERIFICATION,
    component: () => import('./pages/Verification'),
    meta: {
      allowedInquiryStatuses: [InquiryStatus.PENDING],
    },
  },
  {
    path: '/:uuid/phone-number-verification',
    name: RouteNames.INQUIRY_PHONE_NUMBER_VERIFICATION,
    component: () => import('./pages/PhoneNumberVerification'),
    meta: {
      allowedInquiryStatuses: [InquiryStatus.PENDING],
    },
  },
  {
    path: '/:uuid/reschedule',
    name: RouteNames.INQUIRY_RESCHEDULE,
    component: () => import('./pages/Reschedule'),
    meta: {
      allowedInquiryStatuses: [InquiryStatus.IN_PROGRESS, InquiryStatus.SCHEDULED],
    },
  },
  {
    path: '/:uuid/self-showing/start',
    name: RouteNames.INQUIRY_SELF_SHOWING_START,
    component: () => import('./pages/SelfShowing/SelfShowingStart'),
    meta: {
      allowedInquiryStatuses: [InquiryStatus.SCHEDULED],
    },
  },
  {
    path: '/:uuid/self-showing/guidelines',
    name: RouteNames.INQUIRY_SELF_SHOWING_GUIDELINES,
    component: () => import('./pages/SelfShowing/SelfShowingGuidelines'),
    meta: {
      allowedInquiryStatuses: [InquiryStatus.SCHEDULED],
    },
  },
  {
    path: '/:uuid/self-showing/finish',
    name: RouteNames.INQUIRY_SELF_SHOWING_FINISH,
    component: () => import('./pages/SelfShowing/SelfShowingFinish'),
    meta: {
      allowedInquiryStatuses: [InquiryStatus.SCHEDULED],
    },
  },
  {
    path: '/:uuid/tour-not-available',
    name: RouteNames.INQUIRY_TOUR_NOT_AVAILABLE,
    component: () => import('./pages/TourNotAvailable'),
  },
  {
    path: '/video/:meetingName',
    name: RouteNames.VIDEO_MEETING,
    component: () => import('./pages/VideoMeetingRoom'),
  },
  {
    path: '/inspections/:uuid',
    name: RouteNames.INSPECTION_HOME,
    component: () => import('./pages/InspectionTenant'),
  },
  {
    path: '/inspections/:uuid/choose-window',
    name: RouteNames.INSPECTION_CHOOSE_TIME,
    component: () => import('./pages/ChooseInspectionWindow'),
  },
  {
    path: '*',
    name: RouteNames.NOT_FOUND,
    component: () => import('./pages/NotFoundError'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
});

function scrollBehavior(to, from, savedPosition) {
  const scrollerElement = document.querySelector('.main-scroll') || window;
  if (savedPosition) {
    scrollerElement?.scrollTo(savedPosition);
  } else {
    scrollerElement?.scrollTo({
      top: 0,
      x: 0,
      y: 0,
    });
  }
}

router.beforeEach((to, from, next) => {
  if (isBackToUnitFromInquiry(to, from)) {
    return replaceUnitRouteInInquiryRoute(to, from, next);
  }
  if (isReloadDuringFlow(to)) {
    return returnToFirstPage(to, next);
  }
  if (isDisallowedInquiryStatus(to)) {
    return returnToFirstPage(to, next);
  }
  if (isUnqualified(to)) {
    return replaceToUnqualified(to, next);
  }
  return next();
});

export default router;

function isBackToUnitFromInquiry(to, from) {
  return to.name.startsWith('unit') && from.name && from.name.startsWith('inquiry');
}

function replaceUnitRouteInInquiryRoute(to, from, next) {
  return next({
    name: to.name.replace('unit', 'inquiry'),
    params: {
      uuid: from.params.uuid,
    },
    replace: true,
  });
}

function isReloadDuringFlow(to) {
  // We skip this route and allowing leads to get direct link to this page.
  if (to.name === RouteNames.UNIT_CHOOSE_TOUR_TIME) {
    return false;
  }
  return (
    (to.name.startsWith('unit.') && Object.keys(store.state.unit).length === 0)
    || (to.name.startsWith('inquiry.')
      && Object.keys(store.state.inquiry).length === 0
      && to.name !== RouteNames.INQUIRY_TOUR_NOT_AVAILABLE
      && to.name !== RouteNames.INQUIRY_UNQUALIFIED)
  );
}

function returnToFirstPage(to, next) {
  return next({
    name: to.name.startsWith('inquiry') ? RouteNames.INQUIRY_HOME : RouteNames.UNIT_HOME,
    params: {
      uuid: to.params.uuid,
    },
  });
}

function isDisallowedInquiryStatus(to) {
  const allowedInquiryStatuses = get(to, 'meta.allowedInquiryStatuses', []);

  if (Object.keys(store.state.inquiry).length === 0 || !allowedInquiryStatuses.length) {
    return false;
  }

  return !allowedInquiryStatuses.includes(store.state.inquiry.status);
}

function isUnqualified(to) {
  return to.name !== RouteNames.INQUIRY_UNQUALIFIED && store.getters.isUnqualified;
}

function replaceToUnqualified(to, next) {
  return next({
    name: RouteNames.INQUIRY_UNQUALIFIED,
    params: {
      uuid: to.params.uuid,
    },
    replace: true,
  });
}
