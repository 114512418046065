import VueCompositionApi, { ref } from '@vue/composition-api';
import Vue from 'vue';

Vue.use(VueCompositionApi);

const isLoading = ref(false);

const showLoading = () => {
  isLoading.value = true;
};

const hideLoading = () => {
  isLoading.value = false;
};

export {
  isLoading,
  showLoading,
  hideLoading,
};
