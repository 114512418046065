const slimLayoutRoutes = {
  UNIT_HELP: 'unit.help',
  UNIT_SELF_SHOWING_HELP: 'unit.self-showing.help',
  UNIT_MAP_VIEW: 'unit.map',
  UNIT_CHOOSE_TOUR_TIME: 'unit.choose-tour-time',
  UNIT_CONTACT_INFO_FORM: 'unit.contact-info-form',
  INQUIRY_HELP: 'inquiry.help',
  INQUIRY_SELF_SHOWING_HELP: 'inquiry.self-showing.help',
  INQUIRY_MAP_VIEW: 'inquiry.map',
  INQUIRY_CHOOSE_UNIT: 'inquiry.choose-unit',
  INQUIRY_CHOOSE_TOUR_TIME: 'inquiry.choose-tour-time',
  INQUIRY_CONTACT_INFO_FORM: 'inquiry.contact-info-form',
  INQUIRY_INFORMATION: 'inquiry.information',
  INQUIRY_QUALIFICATION_CRITERIA: 'inquiry.qualification-criteria',
  INQUIRY_QUALIFICATION_CRITERIA_QUESTION: 'inquiry.qualification-criteria.question',
  INQUIRY_UNQUALIFIED: 'inquiry.unqualified',
  INQUIRY_IDENTIFICATION: 'inquiry.identification',
  INQUIRY_VERIFICATION: 'inquiry.verification',
  INQUIRY_PHONE_NUMBER_VERIFICATION: 'inquiry.phone-number-verification',
  INQUIRY_RESCHEDULE: 'inquiry.reschedule',
  INQUIRY_SELF_SHOWING_START: 'inquiry.self-showing.start',
  INQUIRY_SELF_SHOWING_FINISH: 'inquiry.self-showing.finish',
  INQUIRY_SELF_SHOWING_GUIDELINES: 'inquiry.self-showing.guidelines',
  INQUIRY_TOUR_NOT_AVAILABLE: 'inquiry.tour-not-available',
  INSPECTION_HOME: 'inspection-tenant',
  INSPECTION_CHOOSE_TIME: 'inspection-tenant.choose-window',
  VIDEO_MEETING: 'video-meeting-room',
  NOT_FOUND: 'not-found-error',
  EMBED_UNIT_SCHEDULING: 'embed-unit-scheduling',
};
export const slimLayoutRoutesArray = Object.values(slimLayoutRoutes);

const wideLayoutRoutes = {
  UNIT_HOME: 'unit',
  INQUIRY_HOME: 'inquiry',
  INQUIRY_PREVIEW: 'inquiry.preview',
};

export const wideLayoutRoutesArray = Object.values(wideLayoutRoutes);

export default { ...slimLayoutRoutes, ...wideLayoutRoutes };
