export const CtaPublishListingText = 'contact us';
export default class ShowingProfileTypes {
  static SELF_SHOWING = 'self_showing';

  static HIGH_AVAILABILITY = 'high_availability';

  static OPEN_HOUSE = 'open_house';

  static RESTRICTED = 'restricted';

  static PUBLISH_LISTING = 'publish_listing';
}
