<template>
  <div
    ref="wrapper"
    class="sd-dialog-wrapper"
    :class="{ visible: uiFlags.isVisible }"
    @click="hide"
  >
    <transition
      name="slide-fade"
      mode="out-in"
    >
      <div
        v-if="uiFlags.isVisible"
        class="sd-dialog position-relative"
        @click.prevent.stop
      >
        <i
          v-if="data.cancelable"
          class="sdicon-close close"
          @click="hide"
        />
        <div
          v-if="data.icon"
          class="icon"
          :class="data.iconClasses"
        >
          <i :class="data.icon" />
        </div>
        <h2
          v-if="data.title"
          class="fs-18"
        >
          {{ data.title }}
        </h2>
        <p
          v-if="data.content && data.isHtmlContent"
          v-html="data.content"
        />
        <p v-else-if="data.content">
          {{ data.content }}
        </p>
        <div class="buttons">
          <div class="row gutters-14">
            <div
              v-for="(button, index) in data.buttons"
              :key="index"
              :class="button.classes || 'col'"
            >
              <SdButton
                class="w-100"
                :outline="button.outline"
                @click="buttonClick(button.handler)"
              >
                {{ button.label }}
              </SdButton>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { reactive, defineComponent } from '@vue/composition-api';
import SdButton from './SdButton';

const DEFAULT_ERROR_MESSAGE = 'We cannot process your request. \n Please contact support@showdigs.com for assistance.';

export default defineComponent({
  name: 'SdDialog',
  components: {
    SdButton,
  },
  setup() {
    const uiFlags = reactive({
      isVisible: false,
    });

    const data = reactive({
      title: '',
      content: '',
      icon: '',
      iconClasses: '',
      buttons: [],
      cancelable: true,
    });

    return {
      uiFlags,
      data,
      show,
      hide,
      notify,
      buttonClick,
    };

    function show({
      title, content, icon = '', iconClasses = '', buttons, cancelable = true, isHtmlContent = false,
    }) {
      data.title = title;
      data.content = content;
      data.icon = icon;
      data.iconClasses = iconClasses;
      data.buttons = buttons;
      data.cancelable = cancelable;
      data.isHtmlContent = isHtmlContent;
      uiFlags.isVisible = true;
    }

    function hide() {
      if (!data.cancelable) {
        return;
      }

      uiFlags.isVisible = false;
    }

    function notify(title = 'Error', content = DEFAULT_ERROR_MESSAGE, okText = 'OK', buttonHandler = null, isHtmlContent = false) {
      const icon = 'sdicon-warning';
      const iconClasses = 'text-warning';
      const buttons = [
        {
          label: okText,
          handler: buttonHandler || null,
        },
      ];
      show({
        title,
        content: content || DEFAULT_ERROR_MESSAGE,
        isHtmlContent,
        icon,
        iconClasses,
        buttons,
      });
    }

    function buttonClick(handler) {
      uiFlags.isVisible = false;
      if (handler) {
        handler();
      }
    }
  },
});
</script>

<style lang="scss" scoped>
.sd-dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 1rem;
  background: rgba(theme-color(title), 0.5);
  opacity: 0;
  transition: $transition-fade;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }

  > .sd-dialog {
    width: 100%;
    max-width: 400px;
    padding: 1rem;
    text-align: center;
    background: $white;
    border-radius: $border-radius;

    > .close {
      position: absolute;
      top: 1.125rem;
      right: 1rem;
      color: theme-color(secondary);
      cursor: pointer;
    }

    > .icon {
      margin-bottom: 0.5rem;
      font-size: 48px;
    }

    > h2 {
      margin-bottom: 0.75rem;
    }

    > .buttons {
      margin-top: 1.5rem;
    }
  }
}
</style>
