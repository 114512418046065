<template>
  <component
    :is="component"
    ref="sdButton"
    :to="to || false"
    class="sd-button"
    :class="buttonClasses"
    @click="click"
  >
    <div class="d-flex justify-content-center align-items-center h-100">
      <slot v-if="!uiFlags.isLoading" />
      <SdSpinner
        v-else
        class="mx-auto"
      />
    </div>
  </component>
</template>

<script>
import {
  reactive, onMounted, computed, defineComponent,
} from '@vue/composition-api';
import SdSpinner from './SdSpinner';

export default defineComponent({
  name: 'SdButton',
  components: {
    SdSpinner,
  },
  props: {
    outline: {
      type: Boolean,
      required: false,
      default: false,
    },
    flat: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: Object,
      required: false,
      default: null,
    },
    href: {
      type: String,
      required: false,
      default: '',
    },
    target: {
      type: String,
      required: false,
      default: '_blank',
    },
    round: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    let component = 'button';
    if (props.to) {
      component = 'router-link';
    } else if (props.href) {
      component = 'a';
    }

    const { refs } = context;

    const uiFlags = reactive({
      isOutline: props.outline,
      isFlat: props.flat,
      isLoading: false,
      isRound: props.round,
    });

    onMounted(mounted);

    const buttonClasses = computed(computeButtonClasses);

    return {
      uiFlags,
      component,
      buttonClasses,
      click,
      showLoading,
      hideLoading,
    };

    function mounted() {
      if (props.href) {
        refs.sdButton.setAttribute('href', props.href);
        refs.sdButton.setAttribute('target', props.target);
      }
    }

    function computeButtonClasses() {
      return {
        'button-outline': uiFlags.isOutline,
        'button-flat': uiFlags.isFlat,
        'button-loading': uiFlags.isLoading,
        'button-round': uiFlags.isRound,
      };
    }

    function click(...args) {
      if (uiFlags.isLoading) {
        return;
      }
      context.emit('click', ...args);
    }

    function showLoading() {
      uiFlags.isLoading = true;
    }

    function hideLoading() {
      uiFlags.isLoading = false;
    }
  },
});
</script>

<style lang="scss" scoped>
$disabled-bg:     gray(dark);
$disabled-color:  gray(darker);

a,
button {
  display: inline-block;
  height: 56px;
  padding: 0.5rem 1rem;
  font-size: $btn-font-size;
  text-decoration: none;
  border-radius: $border-radius;
  transition: $btn-transition;

  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
  }

  &:not(.button-outline):not(.button-flat) {
    color: $white;
    background: theme-color(primary);
    box-shadow: 0 0 15px rgba(75, 155, 255, 0.37);

    &:not(.button-loading) {
      &:hover {
        background: theme-color(primary-dark);
      }

      &:active {
        background: darken(theme-color(primary-dark), 5%);
      }
    }

    &[disabled] {
      color: $disabled-color !important;
      background: $disabled-bg !important;
      box-shadow: none !important;
    }

    ::v-deep .sd-spinner {
      --sd-spinner-color: #{$white};
    }
  }

  &.button-outline,
  &.button-flat {
    background: $white;

    &.button-outline {
      color: theme-color(primary);
      border: 1px solid theme-color(primary);
    }

    &.button-flat {
      color: theme-color(secondary);
    }

    &:not(.button-loading) {
      &:hover {
        background: lighten(theme-color(primary), 35%);
      }

      &:active {
        background: lighten(theme-color(primary), 30%);
      }
    }

    &[disabled] {
      color: $disabled-color !important;
      border-color: $disabled-color !important;
    }
  }

  &.button-round {
    height: auto;
    padding: 0.25rem;
    border-radius: 50%;
  }

  ::v-deep i {
    font-size: $btn-icon-font-size;
  }
}
</style>
