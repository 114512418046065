import { get } from 'lodash-es';
import store from '../store';

/* eslint-disable-next-line no-multi-assign */
const pages = {
  InquiryPending: 'Inquiry Pending',
  UnitSchedulingPage: 'Unit Scheduling Page',
  ChooseUnit: 'Choose Unit',
  ChooseTourTime: 'Choose Tour Time',
  ContactInfoForm: 'Contact Info Form',
  Information: 'Information',
  QualificationCriteria: 'Qualification Criteria',
  QualificationCriteriaQuestion: 'Qualification Criteria Question',
  Identification: 'Identification',
  PhoneNumberVerification: 'Phone Number Verification',
  InquiryInProgress: 'Inquiry In Progress',
  VideoMeetingRoom: 'Video Meeting Room',
  InquiryCompleted: 'Inquiry Completed',
  Help: 'Help',
  SelfShowingHelp: 'SelfShowingHelp',
  SelfShowingStart: 'SelfShowingStart',
  Map: 'Map',
  Unqualified: 'Unqualified',
  TourNotAvailable: 'Tour Not Available',
  NotFound: '404 Not Found',
  UnitError: 'Unit Error',
  InquiryError: 'Inquiry Error',
};

const events = {
  HelpPopoverOpened: 'Help Popover Opened',
  HelpPopoverClosed: 'Help Popover Closed',
  ViewListingButtonClicked: 'View Listing Button Clicked',
  NavigationButtonClicked: 'Navigation Button Clicked',
  DateChosen: 'Date Chosen',
  TimeChosen: 'Time Chosen',
  RedirectedToExistingInquiry: 'Redirected To Existing Inquiry',
  ResentPhoneNumberVerification: 'Resent Phone Number Verification',
  Cancelled: 'Cancelled',
  Confirmed: 'Confirmed',
  Rescheduled: 'Rescheduled',
  RatedAgent: 'Rated Agent',
  EnteredApplicationForm: 'Entered Application Form',
  CallSupportButtonClicked: 'Call Support Button Clicked',
  CallListingManagerButtonClicked: 'Call Listing Manager Button Clicked',
  EmailListingManagerButtonClicked: 'Email Listing Manager Button Clicked',
  CallAgentButtonClicked: 'Call Agent Button Clicked',
  ClickSimilarProperty: 'Click Similar Property',
};

/* eslint-disable  */
const init = () => {
  if (!process.env.VUE_APP_SEGMENT_WRITE_KEY) {
    return;
  }

  !function(){
    var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var t=analytics.methods[e];analytics[t]=analytics.factory(t)}analytics.load=function(e,t){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+e+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=t};analytics.SNIPPET_VERSION="4.1.0";
    analytics.load(process.env.VUE_APP_SEGMENT_WRITE_KEY);
  }}();
};
/* eslint-enable */

const page = (name, properties = {}) => {
  if (!process.env.VUE_APP_SEGMENT_WRITE_KEY) {
    return;
  }

  const urlParams = new URLSearchParams(window.location.search);

  window.analytics.page(name, {
    path: window.location.pathname,
    is_admin: Boolean(urlParams.get('admin')),
    source: urlParams.get('source') || null,
    new_lead_ui: true,
    ...properties,
  });
};

const event = (name, properties = {}) => {
  if (!process.env.VUE_APP_SEGMENT_WRITE_KEY) {
    return;
  }

  const urlParams = new URLSearchParams(window.location.search);

  window.analytics.track(name, {
    is_admin: Boolean(urlParams.get('admin')),
    unit_id: store.getters.isUnitLandingPage ? store.state.unit.id : store.state.inquiry.unit_id,
    inquiry_id: get(store.state, 'inquiry.id'),
    lead_id: get(store.state, 'inquiry.lead_id'),
    new_lead_ui: true,
    ...properties,
  });
};

const identify = () => {
  if (!process.env.VUE_APP_SEGMENT_WRITE_KEY) {
    return;
  }

  const lead = get(store.state, 'inquiry.lead');

  if (!lead) {
    return;
  }

  const urlParams = new URLSearchParams(window.location.search);

  window.analytics.identify('lead', {
    id: 'lead',
    lead_id: lead.id,
    first_name: lead.first_name,
    last_name: lead.last_name,
    email: lead.email,
    phone_number: lead.phone_number,
    is_admin: Boolean(urlParams.get('admin')),
  });
};

const tenant = {
  page: (name, inspection) => {
    if (!process.env.VUE_APP_SEGMENT_WRITE_KEY) {
      return;
    }

    window.analytics.page(name, {
      path: window.location.pathname,
      inspection_id: get(inspection, 'id'),
    });
  },
  pages: {
    WAITING: 'Waiting',
    SCHEDULED: 'Scheduled',
    EXPIRED: 'Expired',
    CHOOSE_INSPECTION_WINDOW: 'Choose Inspection Window',
  },
  event: (name, inspection, properties = {}) => {
    if (!process.env.VUE_APP_SEGMENT_WRITE_KEY) {
      return;
    }

    window.analytics.track(name, {
      inspection_id: inspection.id,
      ...properties,
    });
  },
  events: {
    DATE_CHOSEN: 'Date Chosen',
    TIME_WINDOW_CHOSEN: 'Time Window Chosen',
  },
  identify: (inspection) => {
    if (!process.env.VUE_APP_SEGMENT_WRITE_KEY) {
      return;
    }

    window.analytics.identify('tenant', {
      id: 'tenant',
      inspection_id: inspection.id,
    });
  },
};

export {
  init,
  page,
  pages,
  event,
  events,
  identify,
  tenant,
};
