import Vue from 'vue';
import Vuex from 'vuex';
import { set, get } from 'lodash-es';
import moment from 'moment';
import { AsYouType as PhoneNumberFormatter } from 'libphonenumber-js';
import ShowingProfileTypes from '@/constants/ShowingProfile';
import InquirySource from '@/constants/InquirySource';
import api from './services/api';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    error: null,
    hasError: false,
    inquiry: {},
    unit: {},
    showingSlots: [],
    cancellationTime: undefined,
    confirmationDeadline: undefined,
    showConfirmationTiming: undefined,
    warningMessage: undefined,
    terminalTime: undefined,
    selfShowingTime: undefined,
    screeningCriteria: [],
    screeningCriteriaSummary: [],
    screeningCriteriaCustomQuestions: [],
    supportPhoneNumber: undefined,
    isAdmin: false,
    unqualifiedCriteria: [],
    units: [],
    similarUnits: [],
    dailyCode: undefined,
    isShowingSlotsUnavailableForNewInquiry: undefined,
    payload: {
      isVideo: undefined,
      datetime: '',
      fullName: '',
      email: '',
      phoneNumber: '',
      screeningCriteriaAnswers: {
        pet_policy: '',
      },
      customCriteriaAnswers: {},
      source: undefined,
      conversationId: null,
    },
    receiveHousingAssistanceAnswer: null,
  },
  getters: {
    warningMessage(state) {
      return state.warningMessage;
    },
    showConfirmationTiming(state) {
      return state.showConfirmationTiming;
    },
    confirmationDeadline(state) {
      return state.confirmationDeadline;
    },
    isMultiUnitInquiry(state) {
      return state.inquiry.unit_id === null;
    },
    isUnitLandingPage(state) {
      return Object.keys(state.unit).length > 0;
    },
    isWithInquiry(state) {
      return Object.keys(state.inquiry).length > 0;
    },
    isLoaded(state) {
      return Object.keys(state.unit).length > 0 || Object.keys(state.inquiry).length > 0;
    },
    hasError(state) {
      return state.hasError;
    },
    // We have two places where we use showings slots
    // one in the unit scheduling UI and another place in unit preview - this is the unit preview mapper
    mappedInitialShowingSlots(state) {
      const showingSlots = [];
      state.showingSlots?.slots?.forEach((slot) => slot?.time_slots.forEach((timeSlot) => {
        showingSlots.push({
          is_available: slot.is_available,
          ...timeSlot,
        });
      }));
      return showingSlots;
    },
    inquiry(state) {
      return state.inquiry;
    },
    business(state, getters) {
      return getters.inquiry?.unit?.property?.business ?? getters.property.business;
    },
    property(state, getters) {
      return getters.isUnitLandingPage ? state.unit.property : state.inquiry.property;
    },
    unit(state, getters) {
      return getters.isUnitLandingPage ? state.unit : state.inquiry.unit;
    },
    similarUnits(state) {
      return state.similarUnits;
    },
    address(state, getters) {
      const property = getters.property;
      const unit = getters.unit;

      return property?.short_address + (unit && unit.name ? ` - ${unit.name}` : '');
    },
    tour(state) {
      return get(state.inquiry, 'schedule_inquiry.schedule.tour');
    },
    lastShowingProfile(state, getters) {
      if (getters.isUnitLandingPage) {
        return state.unit.last_showing_profile;
      }
      if (!state.inquiry.unit) {
        return {};
      }
      return state.inquiry.unit.last_showing_profile;
    },
    accessHardware(state, getters) {
      return getters.lastShowingProfile.access_hardware;
    },
    getProperty(state) {
      return (path) => get(state, path);
    },
    questionableScreeningCriteria(state, getters) {
      return [
        ...state.screeningCriteria.filter((criterion) => typeof criterion.question !== 'undefined'),
        ...getters.getAdditionalScreeningCriterion,
        ...getters.getCustomScreeningCriterion,
      ];
    },
    getCustomScreeningCriterion(state) {
      return state.screeningCriteriaCustomQuestions;
    },
    getAdditionalScreeningCriterion(state) {
      return state.screeningCriteria.filter((criterion) => criterion.key === 'additional_criteria');
    },
    nonQuestionableScreeningCriteria(state) {
      const excludedScreeningCriteriaKeys = [
        'general_notes',
        'additional_criteria',
        'minimum_credit_score',
        'minimum_income_rent_ratio',
        'eviction_history',
      ];

      return state.screeningCriteria.filter(
        (criterion) => typeof criterion.label !== 'undefined'
          && excludedScreeningCriteriaKeys.includes(criterion.key) === false,
      );
    },
    isQualificationQuestionnaireNeeded(state) {
      const screeningCriteria = state.screeningCriteria;
      const attempts = state.inquiry.screening_criteria_attempts || [];
      return screeningCriteria.length && !attempts.some((attempt) => attempt.is_qualified);
    },
    isUnqualified(state) {
      return state.unqualifiedCriteria.length && state.inquiry.schedule_inquiry === null; // admin scheduled despite disqualification;
    },
    isPhoneNumberVerificationNeeded(state) {
      return !state.inquiry.phone_number_verified_at;
    },
    isShowingProfilePublishListing(state, getters) {
      return getters.lastShowingProfile.type === ShowingProfileTypes.PUBLISH_LISTING;
    },
    firstName(state) {
      const fullName = state.payload.fullName.trim();
      const [firstName] = fullName.split(' ');
      return firstName;
    },
    lastName(state) {
      const fullName = state.payload.fullName.trim();
      const [, ...lastNameArr] = fullName.split(' ');
      return lastNameArr.filter((val) => val).join(' ');
    },
    propertyManagerDetails(state, getters) {
      const property = getters.property;
      const isContactVisible = getters.property.business.should_show_contact;
      return {
        fullName: state.shouldShowContact ? '' : property.listing_manager.full_name,
        businessName: property.business.name,
        phoneNumber: isContactVisible ? property.business.formatted_contact_phone : property.listing_manager.formatted_public_phone_number,
        email: isContactVisible ? property.business.contact_email : '',
        listingsHomepage: `https://listings.${process.env.VUE_APP_ENV === 'production' ? 'showdigs' : 'bluejaynow'}.com/${property.business.id}`,
        logoUrl: state.logoUrl ?? null,
        websiteUrl: state.websiteUrl ?? null,
        businessNameOrOwner: state.business_name_or_owner,
        faqUrl: property.business.faq_url ?? null,
      };
    },
    tourAt(state) {
      const tourAt = get(state, 'inquiry.schedule_inquiry.schedule.tour_at');

      if (!tourAt) {
        return null;
      }

      return moment(tourAt).tz(state.inquiry.property.timezone);
    },
    tourUntil(state) {
      const tourUntil = get(state, 'inquiry.schedule_inquiry.schedule.tour_until');
      if (!tourUntil) {
        return null;
      }

      return moment(tourUntil).tz(state.inquiry.property.timezone);
    },
    isIdentificationNeeded(state, getters) {
      // persona identification hasn't been performed
      const isUnidentified = state.inquiry.is_unidentified;
      if (isUnidentified === false) {
        return false;
      }

      const identityVerification = getters.lastShowingProfile.lease_period?.has_identity_verification;
      return Boolean(identityVerification);
    },
    isDocumentIdVerificationNeeded(state, getters) {
      // persona identification hasn't been performed
      const isUnidentified = state.inquiry.is_unidentified;

      // pixlab verification has been performed
      const alreadyVerified = state.inquiry.lead.is_document_id_verified;

      const identityVerification = getters.lastShowingProfile.lease_period?.has_identity_verification;
      const isSelfShowing = getters.lastShowingProfile.is_self_showing;
      const selfShowingIdVerification = Boolean(getters.business.self_showing_id_verification_disabled_at);
      return !identityVerification && isSelfShowing && isUnidentified && !alreadyVerified && !selfShowingIdVerification;
    },
    isAllUnitsWithShowingProfilePublishListing(state) {
      const { units } = state;

      if (!units.length) {
        return false;
      }

      const hasAtLeastOneNonePublishListingUnit = units.some((unit) => unit?.active_showing_profile?.type !== ShowingProfileTypes.PUBLISH_LISTING);
      return hasAtLeastOneNonePublishListingUnit === false;
    },
  },
  mutations: {
    handleResponse(state, response) {
      if (response) {
        Object.entries(response).forEach(([key, value]) => {
          const camelCaseKey = key.replace(/(_\w)/g, (str) => str[1].toUpperCase());
          state[camelCaseKey] = value;
        });
      }
    },
    handleError(state, error) {
      state.error = error;
      state.hasError = true;
      throw error;
    },
    removeUnit(state) {
      state.unit = {};
    },
    updateProperty(state, { path, value }) {
      set(state, path, value);
    },
    initScreeningCriteriaAnswers(state, questionableCriteria) {
      questionableCriteria.forEach((criterion) => {
        if (criterion.key) {
          Vue.set(state.payload.screeningCriteriaAnswers, criterion.key, '');
        }
        if (criterion.key === 'minimum_income_rent_ratio') {
          Vue.set(state.payload.screeningCriteriaAnswers, criterion.metadata.voucher.key, '');
        }
      });
    },
    updateScreeningCriteriaAttempts(state, attempts) {
      state.inquiry.screening_criteria_attempts = attempts;
    },
  },
  actions: {
    async getUnit({ commit }, uuid) {
      try {
        const response = await api.get(`units/${uuid}`);
        commit('handleResponse', response);
      } catch (error) {
        commit('handleError', error);
        commit('handleResponse', null);
      }
    },
    async getUnitByBusinessAndPMSCode({ commit }, params) {
      try {
        const response = await api.get(`embed/${params.businessId}/${params.unitPmsCode}`);
        commit('handleResponse', response);
        commit('updateProperty', { path: 'payload.isVideo', value: false });
        commit('updateProperty', { path: 'payload.source', value: InquirySource.EMBEDDED_SCHEDULING });
      } catch (error) {
        commit('handleError', error);
        commit('handleResponse', null);
      }
    },
    async getInquiry({ commit, getters }, { uuid, isAdmin }) {
      let url = `inquiries/${uuid}`;
      if (isAdmin) {
        url += '?admin=1';
      }
      try {
        const response = await api.get(url);
        commit('handleResponse', response);
        commit('initScreeningCriteriaAnswers', getters.questionableScreeningCriteria);
      } catch (error) {
        commit('handleError', error);
        commit('handleResponse', null);
      }
    },
    async storeInquiry({ state, commit, getters }) {
      const payload = {
        property_id: state.unit.property_id,
        unit_id: state.unit.id,
        first_name: getters.firstName,
        last_name: getters.lastName,
        email: state.payload.email,
        phone_number: state.payload.phoneNumber,
        source: state.payload.source,
        conversation_id: state.payload.conversationId,
      };

      const response = await api.post('inquiries', payload);

      commit('handleResponse', response);
      commit('removeUnit');
      commit('initScreeningCriteriaAnswers', getters.questionableScreeningCriteria);
    },
    async verifyDocument({ commit, state }, data) {
      const uuid = state.inquiry.uuid;

      const formData = new FormData();
      formData.append('file', data.blob);
      formData.append('type', data.type);

      try {
        const response = await api.post(`inquiries/${uuid}/document-identifications`, formData);
        commit('handleResponse', response);
      } catch (error) {
        commit('handleError', error);
        commit('handleResponse', null);
      }
    },
    async attemptQualify({ commit, state }) {
      const uuid = state.inquiry.uuid;

      const answers = state.payload.screeningCriteriaAnswers;

      const answersObj = {};
      Object.keys(answers).forEach((key) => {
        if (!answers[key]) {
          delete answers[key];
          return;
        }
        if (key === 'move_in_date') {
          answersObj[key] = {
            move_in_date: answers[key],
            tour_at: state.payload.datetime,
          };
        } else {
          answersObj[key] = answers[key];
        }
      });

      answersObj.custom_screening_answers = state.payload.customCriteriaAnswers;

      const screeningCriteriaAttempts = await api.post(`inquiries/${uuid}/screenings`, answersObj);
      commit('updateScreeningCriteriaAttempts', screeningCriteriaAttempts);
    },
    async verifyIdentity({ commit, state }, identificationId) {
      const uuid = state.inquiry.uuid;

      const response = await api.post(`inquiries/${uuid}/identifications`, {
        persona_identification_id: identificationId,
      });

      commit('handleResponse', response);
    },
    async sendPhoneVerificationUrl({ commit, state, getters }) {
      const uuid = state.inquiry.uuid;

      const payload = {
        lead: {
          first_name: getters.firstName,
          last_name: getters.lastName,
          email: state.payload.email,
          phone_number: state.payload.phoneNumber,
        },
        tour_at: state.payload.datetime,
        is_video: state.payload.isVideo,
      };

      const response = await api.post(`inquiries/${uuid}/send-verification`, payload);

      commit('handleResponse', response);

      return response;
    },
    async verifyPhoneToken({ commit, state, getters }, token) {
      const uuid = state.inquiry.uuid;

      const payload = {
        lead: {
          first_name: getters.firstName,
          last_name: getters.lastName,
          email: state.payload.email,
          phone_number: state.payload.phoneNumber,
        },
        verification_token: token,
        tour_at: state.payload.datetime,
        is_video: state.payload.isVideo,
      };

      const response = await api.post(`inquiries/${uuid}/verifications`, payload);

      commit('handleResponse', response);
    },
    async chooseUnit({ commit, state, getters }, unitId) {
      const uuid = state.inquiry.uuid;

      const payload = {
        unit_id: unitId,
      };

      const response = await api.post(`inquiries/${uuid}/choose-unit`, payload);
      commit('handleResponse', { ...response, units: [] });
      commit('initScreeningCriteriaAnswers', getters.questionableScreeningCriteria);
    },
    async cancel({ commit, state }) {
      const uuid = state.inquiry.uuid;

      const response = await api.post(`inquiries/${uuid}/cancel`);

      commit('handleResponse', response);
    },
    async confirm({ commit, state }) {
      const uuid = state.inquiry.uuid;

      const response = await api.post(`inquiries/${uuid}/confirmations`);

      commit('handleResponse', response);
    },
    async schedule({ commit, state, getters }) {
      const uuid = state.inquiry.uuid;

      const payload = {
        lead: {
          first_name: getters.firstName,
          last_name: getters.lastName,
          email: state.payload.email,
          phone_number: state.payload.phoneNumber,
        },
        tour_at: state.payload.datetime,
        is_video: state.payload.isVideo,
      };

      const response = await api.post(`inquiries/${uuid}/schedule`, payload);

      commit('handleResponse', response);
    },
    async reschedule({ commit, state }, tourAt) {
      const uuid = state.inquiry.uuid;

      const payload = { tour_at: tourAt };

      const response = await api.post(`inquiries/${uuid}/reschedule`, payload);

      commit('handleResponse', response);
    },
    async apply({ state }) {
      const id = state.inquiry.id;

      await api.post(`activities/inquiry-application/${id}`);
    },
    rate({ state }, score) {
      const uuid = state.inquiry.uuid;
      const payload = {
        score: score.rating,
        review: score.review,
      };

      return api.post(`inquiries/${uuid}/rates`, payload);
    },
    getShowingSlots({ state }) {
      const uuid = state.inquiry.uuid;

      return api.get(`inquiries/${uuid}/showing-slots`);
    },
    async setAccessAttempts({ commit, state }, payload) {
      const uuid = state.inquiry.uuid;

      const response = await api.post(`inquiries/${uuid}/access-attempts`, payload);

      commit('handleResponse', response);
    },
    async completeTour({ commit, state }, payload) {
      const uuid = state.inquiry.uuid;

      const response = await api.post(`inquiries/${uuid}/completions`, payload);

      commit('handleResponse', response);
    },
    async markInquiryAsUninterested({ commit, state }) {
      const uuid = state.inquiry.uuid;

      const response = await api.put(`inquiries/${uuid}`, {
        is_uninterested: true,
      });

      commit('handleResponse', response);
    },
    async setLeadContactDetails({ commit }, { phoneNumber, email, fullName }) {
      const phoneNumberFormatted = phoneNumber ? new PhoneNumberFormatter('US').input(phoneNumber) : '';
      commit('updateProperty', { path: 'payload.fullName', value: fullName ?? '' });
      commit('updateProperty', { path: 'payload.phoneNumber', value: phoneNumberFormatted });
      commit('updateProperty', { path: 'payload.email', value: email ?? '' });
    },

    async setPageReferer({ commit }, sourceName) {
      commit('updateProperty', { path: 'payload.source', value: sourceName });
      commit('updateProperty', { path: 'inquiry.source.name', value: sourceName });
    },

    async setConversationId({ commit }, conversationId) {
      commit('updateProperty', { path: 'payload.conversationId', value: conversationId });
    },

    async setNameAndPhoneFromEmbed({ commit }, { name, phone }) {
      if (phone) {
        commit('updateProperty', { path: 'payload.phoneNumber', value: phone });
      }
      if (name) {
        commit('updateProperty', { path: 'payload.fullName', value: name });
      }
    },
  },
});

export default store;
