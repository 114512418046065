import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

api.interceptors.response.use(getResponseData, getErrorData);

function getResponseData(response) {
  return response.data;
}

function getErrorData(error) {
  return Promise.reject(error);
}

export default api;
