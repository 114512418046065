var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(_vm.uiFlags.isWrapperVisible)?_c('div',{class:[_vm.uiFlags.uiLayoutDefinitions.bgColor, _vm.uiFlags.uiLayoutDefinitions.isWideLayout ? 'container-full' : 'container-narrow']},[_c('div',{class:{
        'main-scroll': _vm.isScrollingFromInnerContainer,
        'overflow-auto': _vm.isScrollingFromInnerContainer,
        'h-100': _vm.isScrollingFromInnerContainer,
      }},[(_vm.$store.getters.isLoaded && _vm.uiFlags.isNavigationBarVisible)?_c('SlideTransition',[_c('NavigationBar')],1):_vm._e(),_c('SlideTransition',[(_vm.shouldShowProgressBar)?_c('StepsProgress'):_vm._e()],1),_c('transition',{attrs:{"name":_vm.$route.meta.transition ? _vm.$route.meta.transition.name : '',"mode":_vm.$route.meta.transition ? _vm.$route.meta.transition.mode : ''}},[_c('keep-alive',{attrs:{"exclude":_vm.excludedCacheRoutes}},[_c('router-view',{key:_vm.$route.fullPath,class:{
              'main-content': !_vm.isMapRoute,
              'main-content-map': _vm.isMapRoute,
              'mt-n12': _vm.uiFlags.uiLayoutDefinitions.isRouteWithHeaderPicture,
            }})],1)],1)],1),_c('transition',{attrs:{"name":_vm.$route.meta.transition ? _vm.$route.meta.transition.name : '',"mode":_vm.$route.meta.transition ? _vm.$route.meta.transition.mode : ''}},[_c('div',{key:_vm.$route.fullPath,class:{ animation: _vm.$route.params.animate },attrs:{"id":"sd-container-bottom-portal-wrapper"}},[_c('PortalTarget',{attrs:{"id":"sd-container-bottom-wrapper","name":"sd-container-bottom"}})],1)]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.uiFlags.isLoading)?_c('div',{attrs:{"id":"loader-container"}},[_c('SdSpinner')],1):_vm._e()]),_c('SdDialog',{ref:"dialog"}),_c('SdToast',{ref:"toast"}),_c('PortalTarget',{attrs:{"name":"sd-modal","slim":""}}),(_vm.uiFlags.uiLayoutDefinitions.isWideLayout)?_c('Footer'):_vm._e()],1):_c('router-view',{key:_vm.$route.fullPath}),(!_vm.uiFlags.uiLayoutDefinitions.isWideLayout)?_c('Footer'):_vm._e(),_c('LightBox')],1)}
var staticRenderFns = []

export { render, staticRenderFns }