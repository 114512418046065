<template>
  <div class="sd-spinner">
    <div class="dot" />
    <div class="dot" />
    <div class="dot" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'SdSpinner',
});
</script>

<style lang="scss" scoped>
.sd-spinner {
  --sd-spinner-color: #{theme-color(primary)};

  display: flex;
  justify-content: space-between;
  width: 52px;
  height: 13px;

  > .dot {
    width: 13px;
    height: 13px;
    background-color: var(--sd-spinner-color);
    border-radius: 50%;
    animation: flow 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }
}

@keyframes flow {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
</style>
