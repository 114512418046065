<template>
  <div
    ref="wrapper"
    class="sd-toast-wrapper"
    :class="data.wrapperClass ? data.wrapperClass : ''"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="uiFlags.isVisible"
        class="sd-toast position-relative"
      >
        <div class="row">
          <div
            v-if="data.icon"
            class="col-auto"
            :class="data.iconClasses"
          >
            <i :class="data.icon" />
          </div>
          <div class="col">
            <h2>
              {{ data.title }}
            </h2>
            <p
              v-if="data.content"
              class="text-secondary mt-3"
            >
              {{ data.content }}
            </p>
          </div>
          <div class="col-auto">
            <i
              class="sdicon-close close text-secondary"
              @click="hide"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { reactive, defineComponent, watch } from '@vue/composition-api';

export default defineComponent({
  name: 'SdToast',
  setup(props, context) {
    const uiFlags = reactive({
      isVisible: false,
    });

    watch(
      () => context.root.$route,
      (curr, prev) => {
        if (prev.name && curr.name && uiFlags.isVisible) {
          hide();
        }
      },
    );

    const data = reactive({
      title: '',
      content: '',
      icon: '',
      iconClasses: '',
      position: 'top',
    });

    return {
      uiFlags,
      data,
      show,
      hide,
    };

    function show({
      title, content, icon = '', iconClasses = '', timeout = 8000, position = 'top', anchorEl, wrapperClass = '',
    }) {
      data.title = title;
      data.content = content;
      data.icon = icon;
      data.iconClasses = iconClasses;
      data.wrapperClass = wrapperClass;
      data.position = position;
      uiFlags.isVisible = true;

      calculateToastStyling({ ...data, anchorEl });
      window.addEventListener('resize', () => {
        calculateToastStyling({ ...data, anchorEl });
      });

      setTimeout(hide, timeout);
    }

    function calculateToastStyling(stylingProps) {
      const wrapperEl = context.refs.wrapper;
      const { anchorEl, position } = stylingProps;
      if (!wrapperEl) {
        return;
      }

      wrapperEl.style.removeProperty('left');
      wrapperEl.style.removeProperty('top');

      if (anchorEl) {
        wrapperEl.style.position = 'absolute';
        const containerSelectorNames = '.container, .container-narrow, .container-full';
        const appContainer = '#app';
        const appScrollTop = document.querySelector(appContainer)?.scrollTop || document.documentElement.scrollTop;
        const contentScrollLeft = document.querySelector(containerSelectorNames).scrollLeft || document.documentElement.scrollLeft;
        const scrollerLeft = document.querySelector(containerSelectorNames)?.getBoundingClientRect?.()?.left || 0;
        const el = document.querySelector('.sd-toast-wrapper');
        const toastPadding = window.getComputedStyle(el)?.getPropertyValue?.('padding').replace('px', '');
        wrapperEl.style.top = `${appScrollTop + anchorEl.clientHeight + anchorEl.getBoundingClientRect().top}px`;
        wrapperEl.style.left = `${anchorEl.getBoundingClientRect().left - contentScrollLeft - scrollerLeft - toastPadding}px`;
        wrapperEl.style['box-sizing'] = 'content-box';
      } else if (position === 'bottom') {
        const footerHeight = document.querySelector('#footer')?.clientHeight;
        wrapperEl.style.bottom = `${footerHeight || 68}px`;
      }
    }

    function hide() {
      uiFlags.isVisible = false;
    }
  },
});
</script>

<style lang="scss" scoped>
.sd-toast-wrapper {
  position: fixed;
  z-index: 999999999;
  padding: 1rem;
  pointer-events: none;

  > .sd-toast {
    box-sizing: border-box;
    width: 100%;
    padding: 1rem;
    background: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow-lg;
    pointer-events: auto;

    .close {
      cursor: pointer;
    }
  }
}
</style>
