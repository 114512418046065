<template>
  <div
    v-if="step"
    class="mt-6 px-4"
  >
    <div class="row align-items-center">
      <div class="col-auto text-uppercase text-primary">
        Step {{ step }}/{{ totalSteps }}
      </div>
      <div
        class="col"
      >
        <div id="progress-bar">
          <div
            id="progress-bar-fill"
            :style="progressBarFillStyle"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'StepsProgress',
  setup(props, context) {
    const step = computed(computeStep);
    const totalSteps = ref(computeTotalSteps());
    const progressBarFillStyle = computed(computeProgressBarFillStyle);

    return {
      step,
      totalSteps,
      progressBarFillStyle,
    };

    function computeStep() {
      const routeName = context.root.$route.name;

      if (routeName.includes('.choose-tour-time')) {
        return 1;
      }

      if (routeName.includes('.contact-info-form')) {
        return 2;
      }

      if (routeName.includes('.qualification-criteria')) {
        return 3;
      }

      // Assuming the route is `inquiry.identification`

      if (totalSteps.value === 4) {
        return 4;
      }

      return undefined;
    }

    function computeTotalSteps() {
      let totalStepsValue = 2;

      if (context.root.$store.getters.isQualificationQuestionnaireNeeded) {
        totalStepsValue += 1;
      }

      if (context.root.$store.getters.isIdentificationNeeded) {
        totalStepsValue += 1;
      }

      return totalStepsValue;
    }

    function computeProgressBarFillStyle() {
      const width = (100 / totalSteps.value) * step.value;
      return {
        width: `${width}%`,
      };
    }
  },
});
</script>

<style lang="scss" scoped>
  #progress-bar {
    height: 7px;
    background: gray(light);
    border-radius: 100px;

    > #progress-bar-fill {
      height: 7px;
      background: theme-color(primary);
      border-radius: 100px;
      transition: width 500ms ease-in-out;
    }
  }
</style>
