import Vue from 'vue';
import VueCompositionApi, { ref } from '@vue/composition-api';
import VuePortal from 'portal-vue';
import * as Sentry from '@sentry/vue';
import moment from 'moment-timezone';
import * as VueGoogleMaps from 'vue2-google-maps';
import { isMobileViewport } from '@/utils/deviceUtils';
import * as Segment from './services/segment';
import App from './App.vue';
import router from './router';
import store from './store';
import './styles/app.scss';
import cacheInvalidation from './services/cacheInvalidation';
import routeUtils from './utils/routeUtils';

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    ignoreErrors: [
      'ChunkLoadError',
      'NavigationDuplicated',
      'User denied Geolocation',
      'Loading CSS chunk',
      'Network Error',
      "The meeting you're trying to join does not exist",
      'instantSearchSDKJSBridgeClearHighlight',
      /.*Redirected when going from.*/,
      /.*Expected mapDiv of type HTMLElement but was passed undefined.*/,
    ],
    logErrors: process.env.VUE_APP_ENV !== 'production',
    debug: process.env.VUE_APP_ENV !== 'production',
    sendDefaultPii: true,
    environment: process.env.VUE_APP_ENV,
  });
}

Segment.init();

moment.tz.setDefault('UTC');

Vue.use(VueCompositionApi);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
  },
  // autobindAllEvents: false,
  installComponents: false,
});

Vue.use(VuePortal);

Vue.config.productionTip = false;

const isMobile = ref(isMobileViewport());
function setAppHeightCssVariable() {
  isMobile.value = isMobileViewport();
}

window.addEventListener('resize', setAppHeightCssVariable);

if (!['production', 'staging', 'epic'].includes(process.env.VUE_APP_ENV)) {
  window.store = store;
  window.router = router;
}

new Vue({
  router,
  store,
  provide: () => ({
    $store: store,
    isMobile,
  }),
  render: (h) => h(App),
}).$mount('#app');

setAppHeightCssVariable();
cacheInvalidation();

router.afterEach(routeUtils.conditionallyAddRouteTransition(store, isMobile));
