<template>
  <footer
    id="footer"
    class="d-flex align-items-center justify-content-between px-3"
  >
    <img
      src="../assets/showdigs-logo-gray.svg"
      alt="Showdigs"
    >
    <div>
      <a
        href="https://www.showdigs.com/privacy"
        target="_blank"
        class="fs-13 text-secondary mr-3"
      >Privacy Policy</a>
      <img
        src="../assets/images/equal-housing-opportunity-logo.svg"
        height="20"
        width="auto"
        alt="Equal Housing Opportunity"
      >
    </div>
  </footer>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Footer',
});
</script>

<style lang="scss" scoped>
#footer {
  min-height: $footer-height;
  margin-top: auto;
  background-color: $body-bg;
}
</style>
